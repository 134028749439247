.marquee {
  height: 25px;
  // width: 420px;
  width: 100%;

  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 10s linear infinite;
}

.marquee .marquee-group {
  float: left;
  width: 50%;
}

.marquee .marquee-message {
  margin-right: 40px;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}
