// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import 'google-webfonts';

@import 'bootstrap';

@import 'icomoon';

@import 'fontawesome';

// TODO : use scss to create leaner file sixe
@import 'admin-lte';
@import 'admin-lte-skin-blue';
// @import 'admin-lte/AdminLTE.scss';
// @import 'admin-lte/skins/skin-blue.scss';


// plugins
// @import 'icheck';
@import 'select2';
@import 'select2-bootstrap';
@import 'jasny-fileinput';
@import 'bootstrap3-wysihtml5';
@import 'bootstrap-datepicker3';

@import 'marquee';

@import 'calendar';
@import 'timepicker';
@import 'design';

.form-control{
  height: 33px;
}


// .modal{
//   background: pink;
// }
table.table{
  .text-right{
    text-align: right;
  }
  .text-left{
    text-align: left;
  }
  th{
    text-align: center; 
  }
}

.sl-alert-container{
  position: fixed;
  z-index: 999;
  width: 90%;
  bottom: 0;
  overflow: visible;
  left: 5%;
  .alert{
    margin-bottom: 10px;
    width: 100%;
  }
}

.sl-table-lead{
  background-color: #f9f9f9;
}

.ghost {
  opacity: 0.4;
}
.chosen {
  background-color: #999;
}

@media (min-width: 768px){
  .form-inline .form-control {
    width: 100%;
  }  
}


.wysiwyg{
  overflow:scroll;
  max-height:300px;
}

.info-box-icon{
  .fa{
    margin-top: 23px;
  }
}

.bootstrap-timepicker-widget{
  z-index: 10000 !important;
}

.event-col{
  position: absolute;
  top:0;
  left:95px;
  // background-color: yellow;
  padding: 1px 5px;
  overflow: hidden;
  width:200px;
  height: 40px;
}

.time-slot{
  height: 10px;
}

.bg-red {
  a{
    color:#fff;    
  }
}

.box-overflow{
  .box-body{
    overflow: visible;
  }
}
.tooltip{
  .tooltip-arrow{
  }
  .tooltip-inner{
    width: 200px;
  }
}

.chat .item>.attachment{
  margin-bottom: 10px;
}

*, *:before, *:after {box-sizing:  border-box !important;}

.masonry-row {
 -moz-column-width: 30em;
 -webkit-column-width: 30em;
 -moz-column-gap: 1em;
 -webkit-column-gap:1em; 
  
}

.masonry-col {
 display: inline-block;
 padding:  .25rem;
 width:  100%; 
}

.skin-blue .sidebar-menu .header.header-white{
  background: #00114B;
  padding: 30px;
}