
.login-page{
    background: #ECF0F5;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}
.login-info-wrapper{
   position: relative;
   background-image: url(img/Login-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
.bg-login{
    
}

#add-service {
    color: #05790f;
}

.info-logo{
    margin-top: 18px;
    margin-left: 3px;
}
.info-logo img{
    height: 40px;
    width: auto;
}

h1.login-title{
    text-align: right;
    color: rgb(255, 0, 43);
    font-size: 4em;
    font-weight: 700;
    margin-top: 5vh;
    margin-right: 35px;
    // margin-bottom: 250px;
    min-height: 65vh;

}
.info-bottom{
    position: relative;
    background: rgba(255,255,255,0.5);
    padding: 10px;
    font-size: 11px;
}
.accred-img img{
    height: 60px;
    width: auto;
}
.login-form-wrapper{
    position: relative;
    margin-top: 150px;
}
.login-form{
    position: relative;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.login-form .form-group{
    margin-bottom: 8px;
}
.login-form .form-control{
    background-color: transparent;
}
.forgotten{
    display: block;
    text-align: right;
    margin-top: 5px;
    font-size: 11px;
    margin-bottom: 15px;
}
.forgotten a{
    color: #B3B3B3;
}
.forgotten a:hover{
    color: #3C8DBC;
}
.btn-login{
    padding-left: 25px;
    padding-right: 25px;
}

img.tuv-logo45{
    height: 35px;
    width: auto;
    margin-top: 9px;    
}
.user-panel{
    padding: 0;
}
.side-logo{
    position: relative;
    text-align: center;
    background: #fff;
    padding: 10px;
}

/* RECOLOURING STYLE */
.skin-blue .main-header .logo {
    background-color: #034EA2;
}
.btn-primary, .skin-blue .main-header .navbar {
    background-color: #0D90DB;
}
.skin-blue .left-side, .skin-blue .main-sidebar, .skin-blue .wrapper{
    background-color: #00114B;
}
.skin-blue .sidebar-menu>li.active>a, .skin-blue .sidebar-menu>li:hover>a {
    background: #02428B;
}
.skin-blue .sidebar-menu>li>.treeview-menu {
    background: #010540;
}
.alert-success, .bg-green, .callout.callout-success, .label-success, .modal-success .modal-body {
    background: #56D104!important;
}
.alert-danger, .alert-error, .bg-red, .callout.callout-danger, .label-danger, .modal-danger .modal-body{
    background: #FB3030!important;
}
.skin-blue .main-header li.user-header{
    background: #00114B;
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer{
    background-color: #0033FF;
}

.box-body .fetch-widget .widget-count{
  position: absolute;
  top: 0;
  right: 0;
}

// .tab-pane .widget-count{
//     position: relative;
// }
// 
.outside-form{
    overflow: scroll;
    height: 100vh;
    position: relative;
    .quick-link{
        position: absolute;
        right: 20px;
        top:20px;
        z-index: 1;
    }
    .main{
        position: relative;
        z-index: 0;
        padding-bottom: 120px;
        top: 33%;
        // transform: translateY(-50%);
    }
}
